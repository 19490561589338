<template>
  <article class="vacancy-card" :class="{ 'vacancy-card--expanded': expanded }">
    <div class="vacancy-card__header">
      <div class="vacancy-card__heading">
        <h2 class="vacancy-card__title">
          <IconUser class="icon" />
          <span>{{ vacancy.title }}</span>
          <button class="vacancy-card__expand" @click="expanded = !expanded"><IconChevronRight class="icon" /></button>
        </h2>
        <Chip class="vacancy-card__level" not-clickable v-if="vacancy.skill_level" icon>{{ vacancy.skill_level.title }}</Chip>
      </div>
      <VacancyMetaBadges :vacancy="vacancy" />
      <MobileOnly>
        <SkillChips class="vacancy-card__chips" small :skills="chips" />
        <ProjectResponsesBadge v-if="vacancy.responses_count > 0" :count="vacancy.responses_count" />
        <slot name="action" />
      </MobileOnly>
    </div>
    <div class="vacancy-card__body">
      <div>
        <div>
          <h3>Описание:</h3>
          <p>{{ vacancy.description }}</p>
          <DesktopOnly>
            <div class="vacancy-card__footer">
              <SkillChips small :skills="vacancy.skills" />
              <div class="vacancy-card__actions">
<!--                {{vacancy}}-->
<!--                <ProjectResponseButton v-if="user && vacancy.response_status !== null" :count="vacancy.responses_count" />-->
                <slot name="action" />
              </div>
            </div>
          </DesktopOnly>
        </div>
      </div>
    </div>
  </article>

</template>

<script setup>
import { IconUser, IconChevronRight } from '@tabler/icons-vue'
import useForm from "~/composables/useForm";
import {feedback} from "~/api/feedback";

const props = defineProps({
  vacancy: Object
})

const user = inject('user')
const expanded = ref(false)

const chips = computed(
  () => []
    .concat(props.vacancy.skill_level ? [{ id: -1, name: props.vacancy.skill_level.title }] : [])
    .concat(props.vacancy.skills)
)

</script>

<style scoped lang="scss">
.vacancy-card {
  border-radius: 16px;
  border: 1px solid var(--color-elements-quantery);

  &__header, &__body > div > div {
    padding: 18px 24px;
    @media (max-width: 920px) {
      padding: 18px 16px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--color-elements-quantery);
    transition: .4s border-color;
    @media (max-width: 920px) {
      border-color: transparent;
      flex-direction: column;
      align-items: stretch;
    }

    :deep(.base-button) {
      width: 100%;
    }
  }
  &__heading {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: stretch;
    max-width: 100%;
  }
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-elements-primary);
    display: flex;
    gap: 16px;
    align-items: center;
    .icon {
      width: 24px;
      height: 24px;
      color: var(--color-elements-secondary);
      flex-shrink: 0;
    }
    @media (max-width: 920px) {
      width: 100%;
    }
  }
  &__expand {
    margin-left: auto;
    display: none;
    .icon {
      transition: .4s transform;
    }
    @media (max-width: 920px) {
      display: block;
    }
  }
  &__level {
    flex-shrink: 0;
    @media (max-width: 920px) {
      display: none;
    }
  }
  :deep(.vacancy-item__meta) {
    @media (max-width: 920px) {
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      & > .meta-badge {
        font-size: 16px;
        line-height: 24px;
        gap: 16px;
      }
      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__chips {
    display: none !important;
    padding: 0 16px;
    margin-left: -16px;
    width: calc(100% + 32px);
    @media (max-width: 920px) {
      display: flex !important;
    }
  }
  &__body {
    color: var(--color-elements-primary);
    display: grid;
    grid-template-rows: 1fr;
    transition: .4s grid-template-rows;
    @media (max-width: 920px) {
      grid-template-rows: 0fr;
    }

    > div {
      overflow: hidden;
    }

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 8px;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      white-space: pre-line;
    }
  }
  &__footer {
    margin-top: 16px;
    border-top: 1px solid var(--color-separator);
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    @media (max-width: 920px) {
      display: none;
    }

    :deep(.skills) {
      flex-wrap: wrap;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &--expanded {
    .vacancy-card {
      &__header {
        border-color: var(--color-elements-quantery);
      }
      &__body {
        grid-template-rows: 1fr;
      }
      &__expand .icon {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
