<template>
  <BaseButton
    color-primary
    @click.stop="onClick"
    :done="responded"
    :class="$attrs.class"
    :stretch="$attrs.stretch"
    @click="onClick"
  >
    <template v-if="responded">
      <IconCheck v-if="icon" class="icon" />
      {{ response_status === 'request' ? 'Вы приглашены' : 'Вы откликнулись' }}
    </template>
    <template v-else>Оставить отклик</template>
  </BaseButton>
  <FeedbackModal :modal-data="feedbackModalData"  />
  <ClientOnly>
    <PromptModal @provide="prompt = $event"/>
  </ClientOnly>
</template>

<script setup>
import { IconCheck } from '@tabler/icons-vue'
import { sendResponseToVacancy } from '~/api/projects'

defineOptions({ inheritAttrs: false })

const props = defineProps({
  id: Number,
  response_status: String,
  icon: Boolean
})
const router = useRouter()

const prompt = ref()
const user = inject('user')
const feedbackModalData = ref({
  type: 'project',
  vacancy_id: props.id,
  isShow: false,
})

const responded = ref()
watchEffect(() => responded.value = props.response_status !== null)

const myProfileRoute = useMyProfileRoute()

async function onClick() {
  if (!user.value) {
    feedbackModalData.value.isShow = true
    return
  }

  if (responded.value) return
  if (!user.value.is_specialist) {
    if (
      await prompt.value({
        title: `Вы не можете откликаться на проекты, так как не являетесь специалистом`,
        description: 'Чтобы стать специалистом – перейдите в профиль и заполните анкету',
        accept: 'Перейти в профиль'
      })
    ) await router.push({...myProfileRoute.value, hash: '#specialist'})
    return
  }

  responded.value = true
  sendResponseToVacancy(props.id)
    .catch(() => responded.value = false)
}
</script>

<style scoped lang="scss">

</style>
