<template>
  <p class="project-view__desc">{{ project.description }}</p>
  <div class="project-view__vacancies">
    <VacancyCard
      v-for="vacancy in project.vacancies"
      :key="vacancy.id"
      :vacancy="vacancy"
    >
      <template v-if="!project.is_archive && !isOwner" #action>
        <ProjectResponseButton icon v-bind="vacancy" />
      </template>
    </VacancyCard>

  </div>

</template>

<script setup>

const props = defineProps({
  project: Object
})

const user = inject('user')
const isOwner = computed(() => props.project.owner_id === user.value?.id)

</script>

<style scoped lang="scss">
.project-view {
  &__desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-line;
  }
  &__vacancies {
    > * {
      margin-top: 24px;
    }
  }
}
</style>
